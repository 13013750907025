<template>
  <div
      class="modal fade"
      id="CreateClientModal"
      tabindex="-1"
      aria-labelledby="CreateClientModalLabels"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen-md-down">
      <div class="modal-content">
        <div class="modal-header bg-light p-3">
          <h5 class="modal-title" id="CreateClientModalLabel">
            Create a new company
          </h5>
          <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
          ></button>
        </div>
        <form method="POST" @submit.prevent="createClient">
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input
                  type="text"
                  class="form-control"
                  placeholder="Enter name"
                  required
                  v-model="name"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input
                  type="email"
                  class="form-control"
                  placeholder="Enter email"
                  required
                  v-model="email"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Phone Number</label>
              <input
                  type="text"
                  class="form-control"
                  placeholder="Phone number"
                  required
                  v-model="phone"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">Address</label>
              <input
                  type="text"
                  class="form-control"
                  placeholder="Address"
                  required
                  v-model="address"
              />
            </div>

            <!-- Base Example -->
            <div class="form-check mb-3">
              <input v-model="subscribed_to_email" class="form-check-input" type="checkbox" id="subscribedToEmail">
              <label class="form-check-label" for="subscribedToEmail">
                Subscribed to email
              </label>
            </div>

            <div class="mb-3">
              <label class="form-label">About</label>
              <textarea
                  class="form-control"
                  rows="4"
                  placeholder="About"
                  required
                  v-model="about"
              ></textarea>
            </div>


            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="radio"
                  name="isCustomerRadio"
                  id="isCustomerRadio"
                  v-model="account_type"
                  value="customer"
              />
              <label class="form-check-label" for="isCustomerRadio">
                Is Customer
              </label>
            </div>

            <div class="form-check mb-3">
              <input
                  class="form-check-input"
                  type="radio"
                  name="isCounterpartyRadio"
                  id="isCounterpartyRadio"
                  value="counterparty"
                  v-model="account_type"
              />
              <label class="form-check-label" for="isCounterpartyRadio">
                Is Counterparty
              </label>
            </div>
            <Transition>
              <section v-if="account_type === 'counterparty'">
                <div class="mb-3">
                  <label class="form-label">Counterparty Name</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Enter counterparty name"
                      required
                      v-model="name"
                      v-if="is_the_same_as_company_name"
                      disabled
                  />
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Enter counterparty name"
                      required
                      v-model="counterparty_name"
                      v-else
                  />
                </div>
                <div class="form-check mb-2">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      id="is_the_same_as_company_name"
                      v-model="is_the_same_as_company_name"
                  />
                  <label
                      class="form-check-label"
                      for="is_the_same_as_company_name"
                  >
                    The same as company name
                  </label>
                </div>
              </section>
            </Transition>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="edtclosemodal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-success">Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {createCompany} from "@/api/client/ClientApi.js";
import Swal from "sweetalert2";

export default {
  name: "client_create",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      address: "",
      about: "",
      account_type: "",
      counterparty_name: "",
      is_the_same_as_company_name: false,
      subscribed_to_email: false
    };
  },
  methods: {
    async createClient() {
      let res = await createCompany({
        name: this.name,
        email: this.email,
        phone: this.phone,
        address: this.address,
        about: this.about,
        is_customer: this.account_type === "customer",
        is_counterparty: this.account_type === "counterparty",
        counterparty_name: this.counterparty_name,
        subscribed_to_email: this.subscribed_to_email
      });
      if (res.ok) {
        this.$emit("client-created");
        document.querySelector("#CreateClientModal .btn-close").click();
      } else {
        await Swal.fire({
          title: "Error",
          icon: "error",
          html: Object.values(res.data)
              .map((item) => {
                return `<p>${item}</p>\n`;
              })
              .join(""),
        });
      }
    },
  },
  watch: {
    is_the_same_as_company_name: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.counterparty_name = this.name;
        } else {
          this.counterparty_name = "";
        }
      },
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
